import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import BlogList from "../components/blog/blog-list"


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query blogHomeQuery {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC },
        filter: {
          fileAbsolutePath: {
              regex: "/\/blog\//"
          }
        },
        limit: 5
      ) {
        nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              description
              author
              quote
              quoteAuthor
              category
              tags
            } 
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <div className="static-page">
        <h1>Welcome</h1>
        <p>
          Ciceros Tenent (CT) welcomes you to a place to discuss and discover historical events and their relation to the present day. Contrarian views and
          re-evaluation of current events from a new perspective are essential to avoiding repetition of past mistakes, and to find meaning in present day events. Our
          philosophy is best described as a <Link to="/about">historical allegory</Link> not of Cicero, but of Cicero's tenants whom the historical figure was a landlord. 
          The contrast between the historical figure gloried, and his mistreated tenant shows how the grey areas dominante history and the present day.
        </p>
        <BlogList title="Recent Articles" posts={data.allMarkdownRemark.nodes}></BlogList>
      </div>
    </Layout>
  )
}

export default IndexPage
